<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="gridWork"
          title="작업계획 목록"
          :columns="gridWork.columns"
          :data="gridWork.data"
          :gridHeight="gridWork.height"
          :editable="editable&&!disabled"
          :isDense="true"
          bgHeaderClass="bg-grid-header-main"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :gridHeightAuto="true"
          :columnSetting="false"
          @rowClick="rowClick"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'dailyFlag'">
              <q-chip
                outline square
                :color="getColors(props.row)"
                text-color="white">
                {{getNames(props.row)}}
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm2">
          <c-card class="cardClassDetailForm" title="작업계획 상세">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- <c-btn label="일일작업계획" icon="manage_search" @btnClicked="historyRowWork" v-if="editable && Boolean(data.workResultId)&&selectedWork" /> -->
                <c-btn label="작업계획 추가" icon="add" @btnClicked="addRowWork" v-if="editable&&!disabled && Boolean(data.workResultId)" />
                <c-btn label="작업계획 삭제" icon="remove" @btnClicked="removeRowWork" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork&&workUpdateMode" />
                <c-btn
                v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"
                  :url="saveWorkUrl"
                  :isSubmit="isSave2"
                  :param="workdata"
                  :mappingType="mappingType2"
                  label="작업계획 저장"
                  icon="save"
                  @beforeAction="saveWork"
                  @btnCallback="saveCallbackWork" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled&&selectedWork"
                  label="항목번호"
                  name="sortOrder"
                  type="number"
                  v-model="workdata.sortOrder">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                <c-text
                  :required="true"
                  :editable="editable&&!disabled&&selectedWork"
                  label="작업명"
                  name="workResultWorkName"
                  v-model="workdata.workResultWorkName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  :required="true"
                  :editable="editable&&!disabled&&selectedWork"
                  codeGroupCd="WO_OPR_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workOprTypeCd"
                  label="작업제어구분"
                  v-model="workdata.workOprTypeCd"
                  @datachange="changeWorkOprType"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-if="workdata.workOprTypeCd !== 'WOTC000001'">
                <c-vendor
                  :editable="editable&&!disabled&&selectedWork"
                  label="작업업체"
                  name="vendorCd"
                  v-model="workdata.vendorCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" v-if="workdata.workOprTypeCd === 'WOTC000001'">
                <c-dept type="edit" :isFirstValue="workUpdateMode" :editable="editable&&!disabled&&selectedWork" label="작업부서" name="workDeptCd" v-model="workdata.workDeptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :range="true"
                  :editable="editable&&!disabled&&selectedWork"
                  type="date"
                  label="작업기간"
                  name="workDts"
                  v-model="workdata.workDts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :isArray="false"
                  :editable="editable&&!disabled&&selectedWork"
                  codeGroupCd="SOP_WORK_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="작업허가서 구분"
                  name="workpermitTypes"
                  v-model="workdata.workpermitTypes"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :isArray="false"
                  :editable="editable&&!disabled&&selectedWork"
                  codeGroupCd="WO_RISK_FACTOR"
                  itemText="codeName"
                  itemValue="code"
                  label="위험요인"
                  name="workRiskFactors"
                  v-model="workdata.workRiskFactors"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid2"
          title="작업계획별 서비스(외주)"
          :columns="grid2.columns"
          :data="workdata.serviceList"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled&&selectedWork"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
          selection="multiple"
          rowKey="vendorCd"
          @table-data-change="tableDataChangeService"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addRowService" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"/>
              <c-btn label="제외" icon="remove" @btnClicked="removeRowService" v-if="editable&&!disabled && Boolean(data.workResultId)&&selectedWork"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-direction',
  props: {
    param: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        workResultId: '',
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      selectedWork: false,
      workerSwss: [],
      workerSwssInfo: '',
      editable: true,
      detailUrl: '',
      updateUrl: '',
      equiplistUrl: '',
      insertequipUrl: '',
      removeequipUrl: '',
      costlistUrl: '',
      insertcostUrl: '',
      removecostUrl: '',
      worklistUrl: '',
      workgetUrl: '',
      saveWorkUrl: transactionConfig.wod.workorder.result.work.insert.url,
      removeworkUrl: '',
      isApproval: false,
      saveUrl: transactionConfig.wod.workorder.result.update.url,
      mappingType: 'PUT',
      mappingType2: 'PUT',
      workUpdateMode: false,
      isSave: false,
      isSave2: false,
      selectedWorkResultWorkId: '',
      data: {
        plantCd: null,  // 사업장코드
        preWorkPlanId: '',  // 작업계획 일련번호
        workPlanId: '',  // 작업계획 일련번호
        workResultId: '',  // 작업결과 일련번호
        workOrderNo: '',  // 작업지시번호
        workResultName: '',  // 작업명
        workDeptCd: '',  // 작업부서
        planUserId: '',  // 작업지시자
        woRequestId: '',  // 정비요청 일련번호
        reqDeptCd: '',  // 요청부서
        reqUserId: '',  // 요청인
        reqDt: '',  // 요청서 발행일
        woWorkTypeCd: null,  // 작업 유형코드
        equipmentCd: '',  // 대상 설비
        funcLocationCd: '',  // 기능위치
        remark: '',  // 정비요청 세부사항
        swpFlag: 'N',  // 안전작업허가서 발행여부
        woWorkPlanStepCd: '',  // 작업진행 상태
        preWoWorkPlanStepCd: '',
        planDt: '',
        planDts: [],
        planStartDt: '',  // 목표 시작일
        planStartTime: '',  // 목표 시작시간
        planStartDts: '',
        planEndDt: '',  // 목표 완료일
        planEndTime: '',  // 목표 종료시간
        planEndDts: '',
        resultDts: [],
        resultStartDt: '',  // 목표 시작일
        resultEndDt: '',  // 목표 완료일
        chgUserName: '',
        chgDtStr: '',
        costList: [],
      },
      wopdata: {
        sortOrder: '',
        workOprTypeCd: null,
        workDts: [],
        contents: '',
        workpermitCds: [],
        workRiskFactors: [],
        vendorCd: '',
        workUsers: [],
        workerCnt: '',
        permitNo: '',
      },
      workdata: {
        workResultId: '',  // 정비오더 일련번호
        workResultWorkId: '',  // 작업계획 일련번호
        workResultWorkName: '',  // 작업내용
        sortOrder: '',  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        workDeptCd: '',  // 작업부서
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        spareList: [],
        deleteSpareList: [],
        workerList: [],
        deleteWorkerList: [],
        workHolidayFlag: 'N',
      },
      gridWork: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '항목번호',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'workResultWorkName',
            field: 'workResultWorkName',
            label: '작업명',
            align: 'left',
            sortable: false,
          },
          {
            name: 'workOprTypeName',
            field: 'workOprTypeName',
            label: '작업제어구분',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workDtsStr',
            field: 'workDtsStr',
            label: '작업기간',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          // {
          //   name: 'dailyFlag',
          //   field: 'dailyFlag',
          //   label: '일일작업완료여부',
          //   align: 'center',
          //   type: 'custom',
          //   style: 'width:120px',
          //   sortable: false,
          // },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '작업부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'workpermitTypeNames',
            field: 'workpermitTypeNames',
            label: '작업허가서구분',
            align: 'center',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'vendorNames',
            field: 'vendorNames',
            label: '서비스 (외주)',
            align: 'center',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '400px',
      },
      grid2: {
        columns: [],
        data: [],
        height: '200px',
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'WORK_ORDER_SERVICE_RESULT',
        taskKey: '',
      },
      dailyListUrl: '',
      dailyAllCnt: 0,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    userInfo() {
      return {
        userItems: this.wopdata.workUsers,
        userText: 'workUserName',
        userValue: 'workUserId',
        deptText: 'workDeptName',
        deptValue: 'workDeptCd',
      }
    },
    disabled() {
      this.$emit('disabledSet', this.disabledObj.disabled)
      return this.disabledObj.disabled;
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.preExistDisabled && this.data.woWorkPlanStepCd && this.data.woWorkPlanStepCd !== 'WWPSC00009' && this.data.woWorkPlanStepCd !== 'WWPSC99999' && this.data.woWorkPlanStepCd !== 'WWPSC99998' && !this.disabledAppr, // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000022', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          workPlanId: this.param.workPlanId
        },
        approvalRequestName: '작업결과 ['+this.data.workResultName+']', // 결재요청명 (문서 title)
        approvalConnId: this.param.workPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
    preExistDisabled() {
      // if (!this.data.preWoWorkPlanStepCd) {
      //   return true;
      // } else {
      //   return this.data.preWoWorkPlanStepCd == 'WWPSC00009';
      // }
      return true;
    },
    gridHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) - 480 : 600;
      return numHeight + 'px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.wod.workorder.result.get.url;
      this.updateUrl = transactionConfig.wod.workorder.result.update.url;
      this.equiplistUrl = selectConfig.wod.workorder.result.equip.url;
      this.insertequipUrl = transactionConfig.wod.workorder.result.equip.insert.url;
      this.removeequipUrl = transactionConfig.wod.workorder.result.equip.delete.url;
      this.costlistUrl = selectConfig.wod.workorder.result.cost.url;
      this.insertcostUrl = transactionConfig.wod.workorder.result.cost.save.url;
      this.removecostUrl = transactionConfig.wod.workorder.result.cost.delete.url;
      
      this.worklistUrl = selectConfig.wod.workorder.result.work.list.url;
      this.workgetUrl = selectConfig.wod.workorder.result.work.get.url;
      this.saveworkUrl = transactionConfig.wod.workorder.result.work.insert.url;
      this.removeworkUrl = transactionConfig.wod.workorder.result.work.delete.url;

      this.dailyListUrl = selectConfig.wod.workorder.daily.list.url;
      if (this.param.woWorkPlanStepCd !== 'WWPSC00003') {
        this.disabledObj.disabled = true;
      }
      this.getDetail();
      this.getColumn();
    },
    getDetail() {
      if (this.param.workPlanId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {workPlanId: this.param.workPlanId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (this.data.woWorkPlanStepCd !== 'WWPSC00003') {
            this.disabledObj.disabled = true;
          } else {
            this.disabledObj.disabled = false;
          }
          this.getWorks();
        },);
      } else {
        this.data.planUserId = this.$store.getters.user.userId;

        if (this.param.newEquipmentCd) {
          this.data.equipmentCd = this.param.newEquipmentCd;
          this.data.plantCd = this.param.plantCd;
          this.data.woWorkTypeCd = this.param.newWoWorkTypeCd;
          this.data.workResultnName = this.param.newTitle;
        }
      }
    },
    getEquipList() {
      if (this.data.workResultId) {
        this.$http.url = this.equiplistUrl;
        this.$http.param = {workResultId: this.data.workResultId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid0.data = _result.data;
          this.$emit('saveWorkResult', this.gridWork.data, this.grid0.data);
        },);
      }
    },
    getColumn() {
      this.$comm.getComboItems('WO_COST_FACOTR_CD').then(_result => {
        this.grid2.columns = [
        {
            required: true,
            name: 'vendorName',
            field: 'vendorName',
            label: '작업업체',
            align: 'center',
            style: 'width: 200px',
            sortable: false,
          },
          {
            required: true,
            name: 'workDt',
            field: 'workDt',
            label: '작업일',
            align: 'center',
            type: 'date',
            style: 'width: 140px',
            sortable: false,
          },
          {
            name: 'costFactorCd',
            field: 'costFactorCd',
            label: '원가요소',
            align: 'center',
            sortable: false,
            style: 'width: 200px',
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            comboItems: _result,
          },
          {
            name: 'manCnt',
            field: 'manCnt',
            label: '작업인원',
            type: 'number',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'priceCost',
            field: 'priceCost',
            label: '인력단가',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'manCost',
            field: 'manCost',
            label: '인건비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'materialCost',
            field: 'materialCost',
            label: '자재비',
            type: 'number',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'sumCost',
            field: 'sumCost',
            label: '합계',
            type: 'cost',
            style: 'width: 120px',
            sortable: false,
          },
        ]
      });
    },
    getWorks() {
      if (this.data.workResultId) {
        this.$http.url = this.worklistUrl;
        this.$http.param = {workResultId: this.data.workResultId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.selectedWork = false;
          this.selectedWorkResultWorkId = '';
          this.workUpdateMode = false;
          this.gridWork.data = _result.data;
          this.workdata = {
            workHolidayFlag: 'N',
          };
        },);
      }
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveDataAppr() {
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              let saveDataCost = this.data.costList.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveDataCost, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.costList = saveDataCost;
              let promises = [
                {
                  func: this.approvalValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    saveData() {
      if (this.param.workPlanId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      }
      this.$refs['editForm1'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              let saveDataCost = this.data.costList.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.$_.forEach(saveDataCost, item => {
                item.regUserId = this.$store.getters.user.userId;
                item.chgUserId = this.$store.getters.user.userId;
              });
              this.data.costList = saveDataCost;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    rowClick(row) {
      this.selectedWork = true;
      this.workUpdateMode = true;
      if (row.workResultWorkId) {
        this.$set(this.attachInfo, 'taskKey', row.workResultWorkId)
      }
      this.selectedWorkResultWorkId = row.workResultWorkId;
      // 상세조회
      this.$http.url = this.workgetUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        workResultId : this.data.workResultId,
        workResultWorkId : row.workResultWorkId,
      }
      this.$http.request((_result) => {
        this.workdata = _result.data;
      },
      () => {
      });
    },
    addRowWork() {
      this.selectedWork = true;
      this.selectedWorkResultWorkId = '';
      this.workUpdateMode = false;
      this.workdata = {
        workResultId: this.data.workResultId,  // 정비오더 일련번호
        workResultWorkId: uid(),  // 작업계획 일련번호
        workResultWorkName: '',  // 작업내용
        sortOrder: (this.gridWork.data.length + 1) * 10,  // 순번
        workOprTypeCd: null,  // 작업제어구분
        workStartDt: '',  // 작업 시작일
        workEndDt: '',  // 작업 종료일
        workDts: [],  // 작업기간
        workpermitTypes: '',  // 작업허가서 종류
        sopWorkPermitId: '',  // 작업허가서 일련번호
        workRiskFactors: '',  // 위험요인
        vendorCd: '',  // 작업업체
        vendorWorkerCnt: '',  // 작업업체 작업인원
        workHolidayFlag: 'N',
        materialList: [],
        deleteMaterialList: [],
        serviceList: [],
        deleteServiceList: [],
        toolList: [],
        deleteToolList: [],
        workerList: [],
        deleteWorkerList: [],
        spareList: [],
        deleteSpareList: [],
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: this.$store.getters.user.userId,  // 수정자 ID
      }
    },
    removeRowWork() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '작업계획을 삭제하시겠습니까?',
        // TODO : 작업계획의 모든 정보가 삭제됩니다.\r\n작업계획을 삭제하시겠습니까?
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.removeworkUrl, this.workdata.workResultWorkId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '작업계획이 삭제되었습니다.', /* 작업계획취소가 처리되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.getWorks();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveWork() {
      if (this.selectedWork && this.workUpdateMode) {
        this.saveWorkUrl = transactionConfig.wod.workorder.result.work.update.url;
        this.mappingType2 = 'PUT';
      } else {
        this.saveWorkUrl = transactionConfig.wod.workorder.result.work.insert.url;
        this.mappingType2 = 'POST';
      }
      this.$refs['editForm2'].validate().then(_result => {
        if (this.workdata.serviceList.length > 0) {
          if (this.$comm.validTable(this.grid2.columns, this.workdata.serviceList)) {
            if (_result) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.workdata.regUserId = this.$store.getters.user.userId;
                  this.workdata.chgUserId = this.$store.getters.user.userId;
                  this.isSave2 = !this.isSave2;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('APP_VALID_ERROR');
            }
          }
        } else {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.workdata.regUserId = this.$store.getters.user.userId;
                this.workdata.chgUserId = this.$store.getters.user.userId;
                this.isSave2 = !this.isSave2;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        }
      });
    },
    saveCallbackWork(_result) {
      this.workdata.workResultWorkId = _result.data;
      this.$http.url = this.worklistUrl;
      this.$http.param = {workResultId: this.data.workResultId};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridWork.data = _result.data;
        this.rowClick(this.workdata);
      },);
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    changeWorkOprType() {
      if (this.workdata.workOprTypeCd === 'WOTC000001') { // 자체작업
        this.workdata.vendorCd = '';
        this.workdata.vendorWorkerCnt = null;
      } else {
        this.workdata.workDeptCd = '';
        this.workdata.workUsers = [];
      }
    },
    addRowService() {
      this.popupOptions.title = '작업업체 추가'; // 작업자 추가
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeaddRowService;
    },
    closeaddRowService(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.workdata.serviceList, { vendorCd: item.vendorCd }) === -1) {
            this.workdata.serviceList.push({
              workResultId: this.data.workResultId,  // 작업지시번호
              workResultWorkId: this.workdata.workResultWorkId,
              vendorCd: item.vendorCd,
              vendorName: item.vendorName,
              workDate: '',
              unitEa: '',
              priceCost: '',
              costFactorCd: '',
              manCost: '',
              materialCost: '',
              sumCost: '',
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C',
            })
          }
        });
      }
    },
    removeRowService() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workdata.deleteServiceList) this.workdata.deleteServiceList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workdata.deleteServiceList, { vendorCd: item.vendorCd }) === -1) {
            this.workdata.deleteServiceList.push(item)
          }
          this.workdata.serviceList = this.$_.reject(this.workdata.serviceList, item);
        })
        this.$refs['grid2'].$refs['compo-table'].clearSelection();
      }
    },
    tableDataChangeService(props, col) {
      if (col.name !== 'manCost' && col.name !== 'materialCost') {
        if (props.row['priceCost'] > 0 && props.row['manCnt'] > 0) {
          this.$set(props.row, 'manCost', parseInt(props.row['priceCost']) * parseInt(props.row['manCnt']));
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        } else {
          this.$set(props.row, 'manCost', 0);
          this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost'] || 0));
        }
      } else {
        this.$set(props.row, 'sumCost', parseInt(props.row['manCost']) + parseInt(props.row['materialCost']));
      }
    },
    getColors(row) {
      var returnColor = '';
      if (row.dailyCompleteCnt === row.dailyAllCnt) {
        returnColor = 'blue';
      } else {
        returnColor = 'green';
      }
      return returnColor;
    },
    getNames(row) {
      var returnContent = '';
      if (row.dailyCompleteCnt === row.dailyAllCnt) {
        returnContent = '완료';
      } else {
        returnContent = '작업중';
      }
      return returnContent;
    },
  }
};
</script>